export const redStaticDot = {
    width: 25,
    height: 25,
    data: new Uint8Array(25 * 25 * 4),

    onAdd: function () {
        const canvas = document.createElement('canvas');
        canvas.width = this.width;
        canvas.height = this.height;
        this.context = canvas.getContext('2d');
    },

    render: function () {
        const radius = 0.4 * this.width;
        const context = this.context;

        // Clear the canvas.
        context.clearRect(0, 0, this.width, this.height);

        // Draw the static circle.
        context.beginPath();
        context.arc(
            this.width / 2,
            this.height / 2,
            radius,
            0,
            Math.PI * 2
        );
        context.fillStyle = 'rgba(255, 100, 100, 1)';
        context.strokeStyle = 'white';
        context.lineWidth = 2;
        context.fill();
        context.stroke();

        // Update the image's data with the data from the canvas.
        this.data = context.getImageData(0, 0, this.width, this.height).data;

        return true;
    }
};

export const greenStaticDot = {
    width: 25,
    height: 25,
    data: new Uint8Array(25 * 25 * 4),

    onAdd: function () {
        const canvas = document.createElement('canvas');
        canvas.width = this.width;
        canvas.height = this.height;
        this.context = canvas.getContext('2d');
    },

    render: function () {
        const radius = 0.4 * this.width;
        const context = this.context;

        // Clear the canvas.
        context.clearRect(0, 0, this.width, this.height);

        // Draw the static circle.
        context.beginPath();
        context.arc(
            this.width / 2,
            this.height / 2,
            radius,
            0,
            Math.PI * 2
        );
        context.fillStyle = 'rgba(100, 255, 100, 1)';
        context.strokeStyle = 'white';
        context.lineWidth = 2;
        context.fill();
        context.stroke();

        // Update the image's data with the data from the canvas.
        this.data = context.getImageData(0, 0, this.width, this.height).data;

        return true;
    }
};

function getCircleColorForSizeTarget(avgFruitDiam, lowerUniBound, upperUniBound, allowedVarieties) {
    if (!allowedVarieties || allowedVarieties.length === 0) {
        return [
            "interpolate",
            ["linear"],
            ["get", "avg_diam"],
            avgFruitDiam / 100 * lowerUniBound, "hsl(54, 59%, 51%)",
            avgFruitDiam / 100 * (lowerUniBound + (upperUniBound - lowerUniBound) / 4), "hsl(122, 42%, 47%)",
            avgFruitDiam / 100 * (lowerUniBound + (upperUniBound - lowerUniBound) / 2), "hsl(200, 73%, 45%)",
            avgFruitDiam / 100 * (lowerUniBound + 3 * (upperUniBound - lowerUniBound) / 4), "hsl(226, 82%, 43%)",
            avgFruitDiam / 100 * upperUniBound, "hsl(275, 89%, 25%)"
        ];
    }
    return [
        'case',
        // Check if the feature's row number is in allowedVarieties
        ['in', ['to-string', ['get', 'variety']], ['literal', allowedVarieties]],
        [
            "interpolate",
            ["linear"],
            ["get", 'avg_diam'],
            avgFruitDiam / 100 * lowerUniBound, "hsl(54, 59%, 51%)",
            avgFruitDiam / 100 * (lowerUniBound + (upperUniBound - lowerUniBound) / 4), "hsl(122, 42%, 47%)",
            avgFruitDiam / 100 * (lowerUniBound + (upperUniBound - lowerUniBound) / 2), "hsl(200, 73%, 45%)",
            avgFruitDiam / 100 * (lowerUniBound + 3 * (upperUniBound - lowerUniBound) / 4), "hsl(226, 82%, 43%)",
            avgFruitDiam / 100 * upperUniBound, "hsl(275, 89%, 25%)"
        ],
        'gray'  // default color if none of the above conditions are met
    ];
}

function getCircleColorForZoneMap(avgValue, lowerBoundPercent, upperBoundPercent, field, allowedVarieties) {
    const lowerBound = avgValue * (lowerBoundPercent / 100);
    const upperBound = avgValue * (upperBoundPercent / 100);

    const getColorExpression = () => [
        "step",
        ["get", field],
        "hsl(0, 100%, 50%)",  // Red for you messed up
        lowerBound, "hsla(115, 89%, 35%, 1)",  // Green for good
        upperBound, "hsl(220, 100%, 50%)" // Blue for needs
    ];

    if (!allowedVarieties || allowedVarieties.length === 0) {
        return getColorExpression();
    }

    return [
        'case',
        ['in', ['to-string', ['get', 'variety']], ['literal', allowedVarieties]],
        getColorExpression(),
        'gray'  // default color for varieties not in allowedVarieties
    ];
}

function getCircleColorForUniform(uniTarg, lowerUniBound, upperUniBound, allowedVarieties) {
    if (!allowedVarieties || allowedVarieties.length === 0) {
        return [
            "interpolate",
            ["linear"],
            ["get", "num_buds"],
            0, "gray",
            1, "hsl(54, 59%, 51%)",
            uniTarg / 100 * lowerUniBound, "hsl(54, 59%, 51%)",
            uniTarg / 100 * (lowerUniBound + (upperUniBound - lowerUniBound) / 4), "hsl(122, 42%, 47%)",
            uniTarg / 100 * (lowerUniBound + (upperUniBound - lowerUniBound) / 2), "hsl(200, 73%, 45%)",
            uniTarg / 100 * (lowerUniBound + 3 * (upperUniBound - lowerUniBound) / 4), "hsl(226, 82%, 43%)",
            uniTarg / 100 * upperUniBound, "hsl(275, 89%, 25%)"
        ];
    }
    return [
        'case',
        // Check if the feature's row number is in allowedVarieties
        ['in', ['to-string', ['get', 'variety']], ['literal', allowedVarieties]],
        [
            "interpolate",
            ["linear"],
            ["get", 'num_buds'],
            uniTarg / 100 * lowerUniBound, "hsl(54, 59%, 51%)",
            uniTarg / 100 * (lowerUniBound + (upperUniBound - lowerUniBound) / 4), "hsl(122, 42%, 47%)",
            uniTarg / 100 * (lowerUniBound + (upperUniBound - lowerUniBound) / 2), "hsl(200, 73%, 45%)",
            uniTarg / 100 * (lowerUniBound + 3 * (upperUniBound - lowerUniBound) / 4), "hsl(226, 82%, 43%)",
            uniTarg / 100 * upperUniBound, "hsl(275, 89%, 25%)"
        ],
        'gray'  // default color if none of the above conditions are met
    ];
}

function getCircleColorForTree(uniTarg, lowerUniBound, upperUniBound, field, allowedVarieties) {
    if (!allowedVarieties || allowedVarieties.length === 0) {
        return [
            "interpolate",
            ["linear"],
            ["get", field],
            uniTarg / 100 * lowerUniBound, "hsl(54, 59%, 51%)",
            uniTarg / 100 * (lowerUniBound + (upperUniBound - lowerUniBound) / 4), "hsl(122, 42%, 47%)",
            uniTarg / 100 * (lowerUniBound + (upperUniBound - lowerUniBound) / 2), "hsl(200, 73%, 45%)",
            uniTarg / 100 * (lowerUniBound + 3 * (upperUniBound - lowerUniBound) / 4), "hsl(226, 82%, 43%)",
            uniTarg / 100 * upperUniBound, "hsl(275, 89%, 25%)"
        ];
    }

    return [
        'case',
        // Check if the feature's row number is in allowedVarieties
        ['in', ['to-string', ['get', 'variety']], ['literal', allowedVarieties]],
        [
            "interpolate",
            ["linear"],
            ["get", field],
            uniTarg / 100 * lowerUniBound, "hsl(54, 59%, 51%)",
            uniTarg / 100 * (lowerUniBound + (upperUniBound - lowerUniBound) / 4), "hsl(122, 42%, 47%)",
            uniTarg / 100 * (lowerUniBound + (upperUniBound - lowerUniBound) / 2), "hsl(200, 73%, 45%)",
            uniTarg / 100 * (lowerUniBound + 3 * (upperUniBound - lowerUniBound) / 4), "hsl(226, 82%, 43%)",
            uniTarg / 100 * upperUniBound, "hsl(275, 89%, 25%)"
        ],
        'gray'  // default color if none of the above conditions are met
    ];
}

export function getPlotTypeConfig(avgNumBuds, avgFruitDiam, uniTarg, lowerUniBound, upperUniBound, spacingTarg, heightTarg, treeDiamTarg, vigorTarg, allowedVarieties, viewAutoMovedTrees, viewZoneMap) {
    let trunkAuditFeatureFilters = ['all'];
    if (viewAutoMovedTrees == false) {
        trunkAuditFeatureFilters.push(["!in", "audit_type", "automatically_moved_delete"]);
    }
    return {
        'regular': {
            'id': 'trees-point',
            'type': 'circle',
            'source': 'trees',
            'source-layer':'trees',
            'minzoom': 16,
            'filter': ['all',
                ['!=', ['get', 'num_buds'], ''],
                ['!=', ['get', 'num_buds'], null],
                ['has', 'num_buds']
            ],
            'paint': {
                'circle-opacity': [
                    'case',
                    ['==', ['get', 'num_buds'], 0],
                    0.5,
                    0.8
                ],
                "circle-color": [
                    "interpolate",
                    ["linear"],
                    ["get", "num_buds"],
                    0, "gray",
                    1, "hsl(54, 59%, 51%)",
                    avgNumBuds * .4, "hsl(54, 59%, 51%)",
                    avgNumBuds * .8, "hsl(122, 42%, 47%)",
                    avgNumBuds * 1.2, "hsl(200, 73%, 45%)",
                    avgNumBuds * 1.6, "hsl(226, 82%, 43%)",
                    avgNumBuds * 2, "hsl(275, 89%, 25%)"
                ],
                // Size circle radius by fruits and zoom level
                'circle-radius': ['interpolate', ['linear'], ['zoom'],
                    14, 2,
                    18, 3,
                    20, 10,
                    22, 15
                ],
                // 'circle-opacity': 0.8,
            }
        },
        'size': {
            id: 'trees-point',
            type: 'circle',
            source: 'trees',
            'source-layer':'trees',
            minzoom: 16,
            'filter': ['all',
                ['!=', ['get', 'avg_diam'], ''],
                ['!=', ['get', 'avg_diam'], 0],
                ['!=', ['get', 'avg_diam'], null],
                ['has', 'avg_diam']
            ],
            paint: {
                'circle-opacity': [
                    'case',
                    ['==', ['get', 'avg_diam'], ""],
                    0.3,
                    0.8
                ],
                "circle-color": viewZoneMap
                    ? getCircleColorForZoneMap(avgFruitDiam, lowerUniBound, upperUniBound, "avg_diam", allowedVarieties)
                    : getCircleColorForTree(avgFruitDiam, lowerUniBound, upperUniBound, "avg_diam", allowedVarieties),
                'circle-radius': ['interpolate', ['linear'], ['zoom'],
                    14, 2,
                    18, 3,
                    20, 10,
                    22, 15
                ],
            }
        },
        'tree-vigor': {
            id: 'trees-point',
            type: 'circle',
            source: 'trees',
            'source-layer':'trees',
            minzoom: 16,
            'filter': ['all',
                ['!=', ['get', 'canopy_area_m2'], ''],
                ['!=', ['get', 'canopy_area_m2'], 0],
                ['!=', ['get', 'canopy_area_m2'], null],
                ['has', 'canopy_area_m2']
            ],
            paint: {
                'circle-opacity': [
                    'case',
                    ['==', ['get', 'canopy_area_m2'], ""],
                    0.3,
                    0.8
                ],
                "circle-color": viewZoneMap
                    ? getCircleColorForZoneMap(vigorTarg, lowerUniBound, upperUniBound, "canopy_area_m2", allowedVarieties)
                    : getCircleColorForTree(vigorTarg, lowerUniBound, upperUniBound, "canopy_area_m2", allowedVarieties),
                'circle-radius': ['interpolate', ['linear'], ['zoom'],
                    14, 2,
                    18, 3,
                    20, 10,
                    22, 15
                ],
            }
        },
        'color': {
            'id': 'trees-point',
            'type': 'circle',
            'source': 'trees',
            'source-layer':'trees',
            'minzoom': 16,
            'filter': ['all',
                ['!=', ['get', 'avg_fruit_hue'], ''],
                ['!=', ['get', 'avg_fruit_hue'], null],
                ['has', 'avg_fruit_hue']
            ],
            'paint': {
                'circle-opacity': 0.8,
                'circle-color': [
                    'concat',
                    'hsl(',
                    ['to-string', ['*', ['get', 'avg_fruit_hue'], 2]], // Multiply by 2 here
                    ',',
                    '100%,',
                    '50%)'
                ],
                'circle-radius': ['interpolate', ['linear'], ['zoom'],
                    14, 2,
                    18, 3,
                    20, 10,
                    22, 15
                ],
                'circle-stroke-color': 'black',
                'circle-stroke-width': 0.5
            }
        },
        'canopy_hue': {
            'id': 'trees-point',
            'type': 'circle',
            'source': 'trees',
            'source-layer':'trees',
            'minzoom': 16,
            'filter': ['all', ['has', 'canopy_hsl'], ['!', ['==', ['at', 0, ['get', 'canopy_hsl']], 'null']]],
            'paint': {
                'circle-opacity': 1.0,
                'circle-color': [
                    'concat',
                    'hsl(',
                    ['to-string', ['*', ["at", 0, ["get", "canopy_hsl"]]]],
                    ',',
                    100, // Convert saturation from [0,1] to [0,100]
                    '%,',
                    40, // Convert lightness from [0,1] to [0,100]
                    '%)'
                ],
                // Size circle radius by fruits and zoom level
                'circle-radius': ['interpolate', ['linear'], ['zoom'],
                    14, 2,
                    18, 3,
                    20, 10,
                    22, 15
                ],
                'circle-stroke-color': 'black',
                'circle-stroke-width': 0.5
            }
        },
        'size_target': {
            id: 'trees-size',
            type: 'circle',
            source: 'trees',
            'source-layer':'trees',
            minzoom: 16,
            'filter': ['all',
                ['!=', ['get', 'avg_diam'], ''],
                ['!=', ['get', 'avg_diam'], 0],
                ['!=', ['get', 'avg_diam'], null],
                ['has', 'avg_diam']
            ],
            paint: {
                'circle-opacity': [
                    'case',
                    ['==', ['get', 'avg_diam'], ""],
                    0.3,
                    0.8
                ],
                "circle-color": getCircleColorForSizeTarget(avgFruitDiam, lowerUniBound, upperUniBound, allowedVarieties),
                'circle-radius': ['interpolate', ['linear'], ['zoom'],
                    14, 2,
                    18, 3,
                    20, 10,
                    22, 15
                ],
            }
        },
        'uniform': {
            id: 'trees-point-uni',
            type: 'circle',
            source: 'trees',
            'source-layer':'trees',
            minzoom: 16,
            'filter': ['all',
                ['!=', ['get', 'num_buds'], ''],
                ['!=', ['get', 'num_buds'], null],
                ['has', 'num_buds']
            ],
            paint: {
                "circle-color": viewZoneMap
                    ? getCircleColorForZoneMap(uniTarg, lowerUniBound, upperUniBound, "num_buds", allowedVarieties)
                    : getCircleColorForUniform(uniTarg, lowerUniBound, upperUniBound, allowedVarieties),
                'circle-radius': ['interpolate', ['linear'], ['zoom'],
                    14, 2,
                    18, 3,
                    20, 10,
                    22, 15
                ],
                'circle-opacity': [
                    'case',
                    ['==', ['get', 'num_buds'], 0],
                    0.5,
                    0.8
                ],
            }
        },
        'coverage': {
            'id': 'trees-point',
            'type': 'circle',
            'source': 'trees',
            'source-layer':'trees',
            'minzoom': 16,
            'filter': ['!=', ['get', 'avg_coverage'], null],
            'paint': {
                "circle-color": [
                    "interpolate",
                    ["exponential", 2],
                    ["get", "avg_coverage"],
                    0.5, "#FFFFFF",   // White at 0%
                    1, "#FF0000"  // Red at 100%
                ],
                // Size circle radius by fruits and zoom level
                'circle-radius': ['interpolate', ['linear'], ['zoom'],
                    14, 2,
                    18, 3,
                    20, 10,
                    22, 15
                ],
                'circle-stroke-color': 'black',
                'circle-stroke-width': 0.5
            }
        },
        'sampled_imgs': {
            id: 'trees-point-sample',
            type: 'circle',
            source: 'trees',
            'source-layer':'trees',
            minzoom: 16,
            filter: ['==', ['get', 'has_img'], true],
            paint: {
                'circle-opacity': [
                    'case',
                    ['==', ['get', 'num_buds'], 0],
                    0.5,
                    0.8
                ],
                "circle-color": [
                    "interpolate",
                    ["linear"],
                    ["get", "num_buds"],
                    0, "gray",
                    1, "hsl(54, 59%, 51%)",
                    avgNumBuds * .4, "hsl(54, 59%, 51%)",
                    avgNumBuds * .8, "hsl(122, 42%, 47%)",
                    avgNumBuds * 1.2, "hsl(200, 73%, 45%)",
                    avgNumBuds * 1.6, "hsl(226, 82%, 43%)",
                    avgNumBuds * 2, "hsl(275, 89%, 25%)"
                ],
                'circle-radius': ['interpolate', ['linear'], ['zoom'],
                    14, 2,
                    18, 4,
                    20, 8,
                    22, 15
                ],
                'circle-stroke-color': '#000',
                'circle-stroke-width': 1
            }
        },
        'row_audit': {
            'id': 'trees-for-row',
            'type': 'circle',
            'source': 'trees',
            'source-layer':'trees',
            'minzoom': 16,
            'paint': {
                "circle-color": [
                    'concat',
                    'hsl(',
                    ['to-string', ['%', ['*', 100, ["get", "row_num"]], 255]],
                    ',',
                    100,
                    '%,',
                    45,
                    '%)'
                ],
                // Size circle radius by fruits and zoom level
                'circle-radius': ['interpolate', ['linear'], ['zoom'],
                    14, 2,
                    18, 3,
                    20, 10,
                    22, 15
                ],
                'circle-opacity': 1.0,
            }
        },
        'block_audit': {
            'id': 'block-audit',
            'type': 'circle',
            'source': 'trees',
            'source-layer':'trees',
            'minzoom': 16,
            'paint': {
                "circle-color": 'black',
                // Size circle radius by fruits and zoom level
                'circle-radius': ['interpolate', ['linear'], ['zoom'],
                    14, 1,
                    18, 2,
                    20, 3,
                    22, 15
                ],
                'circle-opacity': 0.8,
            }
        },
        'trunk_audit': {
            'id': 'trees-audit',
            'type': 'circle',
            'source': 'trees',
            'source-layer':'trees',
            'minzoom': 16,
            "filter": trunkAuditFeatureFilters,
            'paint': {
                'circle-opacity': [
                    'case',
                    ['==', ['get', 'num_buds'], 0],
                    1,
                    1
                ],
                'circle-color': [
                    'case', // TODO: Handle location & boundary in pregenerator
                    ['==', ['get', 'audit_type'], 'delete'], 'red',
                    ['==', ['get', 'audit_type'], 'add'], '#00FF00', // Green
                    ['==', ['get', 'audit_type'], 'location'], '#00FFFF', // Cyan
                    ['==', ['get', 'audit_type'], 'boundary'], 'orange',
                    ['==', ['get', 'audit_type'], 'row_spacing'], 'purple',
                    ['==', ['get', 'audit_type'], 'row_position'], 'pink',
                    ['==', ['get', 'audit_type'], 'automatically_moved_delete'], 'maroon',
                    ['==', ['get', 'audit_type'], 'automatically_moved_add'], '#606060',
                    ['==', ['get', 'audit_type'], 'unable_to_map_to_slot'], 'deepskyblue',
                    ['==', ['get', 'audit_type'], 'unable_to_map_to_slot_delete'], '#900C3F',
                    ['<', ['get', 'width'], 1.0], 'yellow',
                    ['>', ['get', 'width'], 4.5], 'yellow',
                    [
                        "interpolate",
                        ["linear"],
                        ["get", "num_buds"],
                        0, "gray",
                        1, "hsl(54, 59%, 51%)"
                    ]
                ],
                // Size circle radius by fruits and zoom level
                'circle-radius': ['interpolate', ['linear'], ['zoom'],
                    14, 2,
                    18, 3,
                    20, 6,
                    22, 8
                ],
                // 'circle-opacity': 0.8,
            }
        },
        'tree_diam': {
            'id': 'trees-point',
            'type': 'circle',
            'source': 'trees',
            'source-layer':'trees',
            'minzoom': 16,
            'paint': {
                'circle-opacity': [
                    'case',
                    ['==', ['get', 'width'], 0],
                    1,
                    1
                ],
                "circle-color": getCircleColorForTree(treeDiamTarg, lowerUniBound, upperUniBound, "width", allowedVarieties),
                // Size circle radius by fruits and zoom level
                'circle-radius': ['interpolate', ['linear'], ['zoom'],
                    14, 2,
                    18, 3,
                    20, 6,
                    22, 8
                ],
                // 'circle-opacity': 0.8,
            }
        },
        'height': {
            'id': 'trees-point',
            'type': 'circle',
            'source': 'trees',
            'source-layer':'trees',
            'minzoom': 16,
            'paint': {
                'circle-opacity': [
                    'case',
                    ['==', ['get', 'canopy_height_m'], 0],
                    1,
                    1
                ],
                "circle-color": getCircleColorForTree(heightTarg, lowerUniBound, upperUniBound, 'canopy_height_m', allowedVarieties),
                // Size circle radius by fruits and zoom level
                'circle-radius': ['interpolate', ['linear'], ['zoom'],
                    14, 2,
                    18, 3,
                    20, 6,
                    22, 8
                ],
                // 'circle-opacity': 0.8,
            }
        },
        'spacing': {
            'id': 'trees-point',
            'type': 'circle',
            'source': 'trees',
            'source-layer':'trees',
            'minzoom': 16,
            'filter': ['all',
                ['!=', ['get', 'tree_spacing'], ''],
                ['!=', ['get', 'tree_spacing'], 0],
                ['has', 'tree_spacing']
            ],
            'paint': {
                'circle-opacity': [
                    'case',
                    ['==', ['get', 'tree_spacing'], 0],
                    1,
                    1
                ],
                "circle-color": getCircleColorForTree(spacingTarg, lowerUniBound, upperUniBound, 'tree_spacing', allowedVarieties),
                // Size circle radius by fruits and zoom level
                'circle-radius': ['interpolate', ['linear'], ['zoom'],
                    14, 2,
                    18, 3,
                    20, 6,
                    22, 8
                ],
                // 'circle-opacity': 0.8,
            }
        }
    };
}